// extracted by mini-css-extract-plugin
export var backgroundTransparent = "rgba(0,0,0,0)";
export var baseColor = "#000";
export var container = "layout-module--container--RoCXy";
export var defaultForegroundColor = "#d9d9d9";
export var footer = "layout-module--footer--lccGK";
export var header = "layout-module--header--aoaNz";
export var highlightColor = "#b99128";
export var main = "layout-module--main--ay8oo";
export var overlay = "layout-module--overlay--s5r6w";
export var overlayBackgroundBottom = "linear-gradient(0, #262626, transparent)";
export var overlayBackgroundColor = "#262626";
export var overlayBackgroundTop = "linear-gradient(0, transparent, #262626)";
export var overlayMain = "layout-module--overlay-main--GjSEB";
export var rootBackgroundColor = "#151515";
export var textColor = "#fff";