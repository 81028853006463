// extracted by mini-css-extract-plugin
export var backgroundTransparent = "rgba(0,0,0,0)";
export var baseColor = "#000";
export var container = "footer-module--container--5GliC";
export var defaultForegroundColor = "#d9d9d9";
export var footer = "footer-module--footer--Z1xkR";
export var highlightColor = "#b99128";
export var overlay = "footer-module--overlay--1NhIB";
export var overlayBackgroundBottom = "linear-gradient(0, #262626, transparent)";
export var overlayBackgroundColor = "#262626";
export var overlayBackgroundTop = "linear-gradient(0, transparent, #262626)";
export var rootBackgroundColor = "#151515";
export var separator = "footer-module--separator--H2vJE";
export var socialLink = "footer-module--social-link--OSvmv";
export var textColor = "#fff";