module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-layout@3.13.0_gatsby@4.13.1/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/packages/gatsby/src/components/layout.tsx"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-canonical-urls@4.13.0_gatsby@4.13.1/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://catherinejauffret.fr"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-image@2.13.0_jra6ebqwkjryocd3u6g7l65xxy/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@4.13.0_2lzlikairpyi3cn6qinlgglwnq/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Collages de Catherine Jauffret","short_name":"Catherine Jauffret","description":"Collages de Catherine Jauffret","start_url":"/","background_color":"#000","theme_color":"#fff","display":"standalone","icon":"src/images/Red-dingue.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"68efcaa66c26a6af90f16ce5309aa2ea"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-offline@5.13.0_ka2clbwrsnttfzdqd3f4h2jph4/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
