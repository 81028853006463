// extracted by mini-css-extract-plugin
export var activePage = "header-module--active-page--Su7qo";
export var backgroundTransparent = "rgba(0,0,0,0)";
export var baseColor = "#000";
export var collapsed = "header-module--collapsed--GRPMG";
export var defaultForegroundColor = "#d9d9d9";
export var fixedHeader = "header-module--fixed-header--gvRof";
export var header = "header-module--header--knWBH";
export var highlightColor = "#b99128";
export var menu = "header-module--menu--MFCcM";
export var overlay = "header-module--overlay--CMIRm";
export var overlayBackgroundBottom = "linear-gradient(0, #262626, transparent)";
export var overlayBackgroundColor = "#262626";
export var overlayBackgroundTop = "linear-gradient(0, transparent, #262626)";
export var rootBackgroundColor = "#151515";
export var textColor = "#fff";