// extracted by mini-css-extract-plugin
export var backgroundLayer = "headerMobile-module--backgroundLayer--a7c-9";
export var backgroundTransparent = "rgba(0,0,0,0)";
export var baseColor = "#000";
export var defaultForegroundColor = "#d9d9d9";
export var fixedHeader = "headerMobile-module--fixed-header--vdeuZ";
export var hamburger = "headerMobile-module--hamburger--aLFDj";
export var hamburgerBox = "headerMobile-module--hamburger-box--dwEMc";
export var hamburgerCollapse = "headerMobile-module--hamburger--collapse--v1J48";
export var hamburgerInner = "headerMobile-module--hamburger-inner--k0-K+";
export var header = "headerMobile-module--header--uviIg";
export var highlightColor = "#b99128";
export var isActive = "headerMobile-module--is-active--P7YMJ";
export var menu = "headerMobile-module--menu--hUErM";
export var overlay = "headerMobile-module--overlay--ZHFkO";
export var overlayBackgroundBottom = "linear-gradient(0, #262626, transparent)";
export var overlayBackgroundColor = "#262626";
export var overlayBackgroundTop = "linear-gradient(0, transparent, #262626)";
export var rootBackgroundColor = "#151515";
export var textColor = "#fff";